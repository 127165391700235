<template>
  <div class="system-login">
    <el-card class="system-login-card">
      <!-- 欢迎登录 -->
      <!-- <div class="login-title">{{ $t("common.welcomeToLogin") }}</div> -->
      <div class="login-content">
        <el-form :model="loginInfo" :rules="loginRules" ref="loginForm" class="demo-ruleForm">
          <el-form-item prop="userName">
            <!-- 请输入用户名、绑定的邮箱 -->
            <el-input type="text" :placeholder="$t('common.userNameHolder')" v-model="loginInfo.username" :maxlength="120"
              autofocus>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <!-- 请输入登录密码 -->
            <el-input type="password" :placeholder="$t('common.userPassHolder')" v-model="loginInfo.password"
              :maxlength="150" show-password>
            </el-input>
          </el-form-item>

          <!-- <el-input
            type="password"
            :placeholder="$t('common.userPassHolder')"
            prefix-icon="iconfont icon-password"
            v-model="loginInfo.password"
            :maxlength="150"
          >
          </el-input> -->
          <!-- <div class= "content-rember">
            <el-checkbox label="记住登录密码" 
                         name="remerberPass"
                         v-model= "remerberPass"
                        class= "remerber-pass"></el-checkbox>
            <span class= "forget-pass">忘记密码</span>
          </div> -->
          <div class="content-btn">
            <!-- 登录 -->
            <el-button type="primary" class="btn-login" @click="submitForm('loginForm')">{{ $t("common.login") }}
            </el-button>
          </div>
          <div class="content-nocount">
            <!-- 没有账号？ -->
            <!-- <span>{{ $t("common.noUserCount") }}</span> -->
            <span>
              <!-- 立即注册 -->
              <el-button type="text" size="mini" @click="gotoPage('register')">{{ $t("common.registerNow") }}
              </el-button>
            </span>
            <!-- 忘记密码 -->
            <!-- <span class="forget-pass" @click="showReGetPass">{{ $t("common.forgetPass") }}</span> -->
          </div>
        </el-form>
      </div>
    </el-card>
    <!-- retrievePass: '找回密码', -->
    <el-dialog :title="$t('common.retrievePass')" :visible.sync="showModel" :close-on-click-modal="false" width="800px">
      <span v-if="showModel">
        <ReGetPass @doColseModel="doColseModel" />
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ReGetPass from "./reGetPass.vue";
import userApi from "@/api/user";
import { mapMutations } from "vuex";
import { setToken } from "@/utils/auth";

export default {
  name: "login",
  components: {
    ReGetPass,
  },
  data () {
    return {
      flag: true, // 用于防抖
      loginInfo: {
        username: "",
        password: "",
        email: "", // 前端校验email格式
      },
      loginRules: {
        // 登录校验
        userName: [
          // { required: true, message: '请输入用户名、邮箱账号', trigger: 'blur' }
          { validator: validateLoginName.bind(this), trigger: "blur" },
        ],
        password: [
          // { required: true, message: '请输入密码', trigger: 'blur' }
          { validator: validateLoginPass.bind(this), trigger: "blur" },
        ],
      },
      remerberPass: false,

      showModel: false, // 是否展示找回密码模态框
    };
  },
  methods: {
    ...mapMutations(["SET_TOKEN"]),

    /** 提交表单（登录） */

    submitForm (formName) {
      //     if (!this.flag) {
      //       return;
      //     }
      //     this.flag = false;

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await userApi.login(this.loginInfo);
          if (res.code == 200) {
            setToken(res.token);
            this.SET_TOKEN(res.token);
            this.$router.push({
              path: "home",
            });
          }
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    /** 跳转页面 */
    gotoPage (name) {
      this.$router.push({ name });
    },

    /** 找回密码 */
    showReGetPass () {
      this.showModel = true;
    },
    doColseModel () {
      this.showModel = false;
    },
  },
};

/** 登录用户名校验 */
function validateLoginName (rule, value, callback) {
  // 不为空
  if (value === "" || String(value).trim() === "") {
    callback(new Error(this.$t("common.loginHolder")));
    return;
  }
  callback();
}
/** 登录密码校验 */
function validateLoginPass (rule, value, callback) {
  // 不为空
  if (value === "" || String(value).trim() === "") {
    callback(new Error(this.$t("common.passHolder")));
    return;
  }
  callback();
}
</script>

<style lang="scss" scoped>
.system-login {
  height: 100vh;
  background-image: url("../../assets/image/login/login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  box-sizing: border-box;
  color: #333333;
  font-size: 14px;

  .system-login-card {
    padding: 10px 10px 30px 10px;
    width: 400px;
    position: absolute;
    right: 15%;
    top: 25%;

    .login-title {
      color: #333333;
      font-size: 24px;
      text-align: center;
      font-weight: 600;
      letter-spacing: 2px;
      margin: 23px 0 53px 0;
    }

    .login-content {

      ::v-deep .el-input {
        caret-color: black;

        .el-input__inner {
          caret-color: black;
        }
      }

      .content-rember {
        position: relative;

        .remerber-pass {
          opacity: 0.43;
        }
      }

      .content-btn {
        margin-top: 20px;

        .btn-login {
          letter-spacing: 10px;
          width: 100%;
          font-size: 16px;
        }
      }

      .content-nocount {
        font-size: 14px;
        color: #a6a6a6;
        letter-spacing: 2px;
        margin-top: 20px;
        position: relative;

        .forget-pass {
          position: absolute;
          right: 0;
          color: #409eff;

          &:hover {
            cursor: pointer;
          }

          &:active {
            opacity: 0.75;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.system-login {
  .system-login-card {
    .login-input {
      .el-input--prefix {
        .el-input__prefix {
          .iconfont {
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
